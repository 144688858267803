import ApplicationController from './application_controller'

export default class extends ApplicationController {
    beforeSaveStaffJob (element, reflex, noop, reflexId) {
        var jobName = $("#job_name_"+element.dataset.id).val();
        var skillName = $("#skill_name_"+element.dataset.id).val();
        const { params } = this.element.reflexData[reflexId]
        this.element.reflexData[reflexId].params = { ...params, job_name: jobName, skill_name: skillName }
    }

    saveStaffJobSuccess(element, reflex, noop, reflexId) {
        $("#skill_name_new").val("");
        $("#job_name_new").val("");
    }

    startDelete(e) {
        $("#delete_job_"+e.currentTarget.dataset.id).addClass("hidden",400);

        if (e.currentTarget.dataset.showsecond) {
            $("#delete_job_confirm_1_"+e.currentTarget.dataset.id).addClass("hidden",400);
            $("#delete_job_confirm_2_"+e.currentTarget.dataset.id).removeClass("hidden",400);
        } else {
            $("#delete_job_confirm_1_"+e.currentTarget.dataset.id).removeClass("hidden",400);
        }
    }

    removeAdjudicationRow(e) {
        $("#adjudication_row_"+e.currentTarget.dataset.id).remove();
    }

    showAdjudicationBlankRow(e) {
        $("#adjudication_row_new").removeClass("hidden",400);
        $("#adjudication_add_button").addClass("invisible",400);
        $("#ignore_new_row_hidden_field").remove();
    }

    showSaveButton(e) {
        $("#save_job_"+e.currentTarget.dataset.id).removeClass("invisible",400);
    }
} 
